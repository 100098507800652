import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAVB2xta8dRSjLqU1MipQ8eymn_dmyvsBI",
  authDomain: "exam-software-88f01.firebaseapp.com",
  projectId: "exam-software-88f01",
  storageBucket: "exam-software-88f01.appspot.com",
  messagingSenderId: "308282443295",
  appId: "1:308282443295:web:cbbabb5fe373b349ca772a",
  measurementId: "G-3NB7W2LLG4",
});

const storage = firebaseApp.storage();

export { storage };
