import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import React, { FC } from "react";
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    padding: 10,
  },
  section: {
    margin: 5,
  },
  subSection: {
    margin: 5,
  },
  infosection: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 15,
  },
  header: {
    width: "100vh",
    height: "10%",
  },
  container: {
    flex: 1,
    flexDirection: "row",
  },
  leftColumn: {
    flexDirection: "column",
    width: 700,
    paddingLeft: 5,
  },
  rightColumn: {
    flexDirection: "column",
    width: 700,
  },
  outcomes: {
    paddingTop: 10,
  },
  table: {
    //@ts-ignore
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "45%",
    borderStyle: "solid",
    borderWidth: 3,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 3,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
  },
  verifySign: {
    paddingTop: 15,
  },
});

const PDFPreview: FC<any> = ({ paper }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src="public/img/Somaiya_Brand.jpeg" />
        </View>
        <View style={styles.infosection}>
          <Text>Academic Year 2020-2021</Text>
          <Text>Semester VII/VIII</Text>
          <Text>Department of Computer Engineering</Text>
          <Text>Test-1</Text>
        </View>
        <View style={styles.container}>
          <View style={styles.leftColumn}>
            <Text>Class: LY</Text>
            <Text>Course: Artificial Intelligence</Text>
            <Text>Date: 25/08/21</Text>
          </View>
          <View style={styles.rightColumn}>
            <Text>Course Code: 1UCEC701</Text>
            <Text>Marks: 15</Text>
            <Text>Time: 45 mins</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Question No.</Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Sub Question No.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Statement of Question</Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Marks</Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>CO</Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>BT Level</Text>
            </View>
          </View>
        </View>
        {paper.map(
          (
            { mainQuestion: { serial: mainSerial, question }, subQuestion },
            index
          ) => {
            return (
              <React.Fragment key={index}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text>{mainSerial})</Text>
                  </View>
                  <View style={styles.tableCol1}>
                    <Text></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text>{question}</Text>
                  </View>
                  <View style={styles.tableCol1}>
                    <Text>10</Text>
                  </View>
                  <View style={styles.tableCol1}>
                    <Text>CO5</Text>
                  </View>
                  <View style={styles.tableCol1}>
                    <Text>2</Text>
                  </View>
                </View>
                {subQuestion?.map(({ question, serial }, subIndex) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol1}>
                        <Text></Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text>
                          {mainSerial}.{serial})
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text>{question}</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text>5</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text>CO5</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text>2</Text>
                      </View>
                    </View>
                  );
                })}
              </React.Fragment>
            );
          }
        )}
        <View style={styles.outcomes}>
          <Text>Course Outcomes:</Text>
          <Text>1. Describe the basic concepts of AI</Text>
          <Text>
            2. Develop a basic understanding of AI building blocks presented in
            intelligent agents.
          </Text>
          <Text>
            3. Choose an appropriate problem-solving method and knowledge
            representation technique.
          </Text>
          <Text>
            4.Design models for reasoning with uncertainty as well as the use of
            unreliable information.
          </Text>
          <Text>
            5.Analyze the strength and weaknesses of AI approaches to knowledge–
            intensive problem solving.
          </Text>
          <Text>
            6. Design and develop AI applications in real world scenarios.
          </Text>
        </View>
        <View style={styles.verifySign}>
          <Text>Verified by:</Text>
          <Text>DQC Member</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFPreview;
