import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useState } from "react";
import { useStateValue } from "../StateProvider";
import { useHistory } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import { useQuery } from "./utils/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(8),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: theme.palette.primary.dark,
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#911717",
    "&:hover": {
      backgroundColor: "#911717",
      boxShadow: "1px 2px 5px black",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#911717",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#911717",
      },
    },
  },
})(TextField);

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const query = useQuery();
  // @ts-ignore
  const [, dispatch] = useStateValue();

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: "SET_TOKEN", token: data.token });
        dispatch({ type: "SET_EMAIL", email: data[data.role].email });
        dispatch({ type: "SET_ROLE", role: data.role });

        sessionStorage.setItem("login", data.token);
        sessionStorage.setItem("email", data[data.role].email);
        sessionStorage.setItem("name", data[data.role].name);
        sessionStorage.setItem("role", data.role);

        const redirect = query.get("redirect");
        redirect ? history.push(`/${redirect}`) : history.push("/reports");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Avatar style={{ fill: "black" }} className={classes.avatar}></Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <CssTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              LOGIN
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="/forgot-password"
                  variant="body2"
                  style={{ color: "#911717" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};
export default Login;
