// import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { useEffect } from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../StateProvider";

const Header: FC = () => {
  const history = useHistory();
  // @ts-ignore
  const [{ token }, dispatch] = useStateValue();
  useEffect(() => {}, [token]);
  return (
    <AppBar position="sticky" style={{ backgroundColor: "#911717" }}>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 2.5rem",
        }}
      >
        {/* <Typography variant="h5">{title}</Typography> */}
        <img
          src={`${process.env.PUBLIC_URL}/img/somaiya_white_logo.png`}
          style={{ marginLeft: -30 }}
          alt="Somaiya Trust Logo"
          height="50px"
        ></img>

        {/* <div>
          <Typography variant="h5">
            K. J. Somaiya Institute of Engineering and Information Technology{" "}
          </Typography>
        </div> */}

        <div>
          <Button
            color="inherit"
            size="large"
            onClick={() => history.push(`/`)}
          >
            Question Builder
          </Button>
          {/* <Button
            color="inherit"
            size="large"
            onClick={() => history.push(`/upload`)}
          >
            Upload
          </Button> */}
          <Button
            color="inherit"
            size="large"
            onClick={() => history.push(`/reports`)}
          >
            Reports
          </Button>

          {token ? (
            <Button
              color="inherit"
              size="large"
              onClick={() => {
                dispatch({ type: "SET_TOKEN", token: null });
                dispatch({ type: "SET_EMAIL", email: null });
                dispatch({ type: "SET_ROLE", role: null });
                sessionStorage.removeItem("login");
                sessionStorage.removeItem("email");
                sessionStorage.removeItem("role");
                history.push("/login");
              }}
            >
              Logout
            </Button>
          ) : (
            <Button
              color="inherit"
              size="large"
              onClick={() => history.push(`/login`)}
            >
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
