import { pdf } from "@react-pdf/renderer";
import { FC, useEffect, useState } from "react";
import PDFPreview from "../preview/PDFPreview";

const RenderInstance: FC<any> = ({ paper }) => {
  const [pdfDataURL, setPdfDataURL] = useState(null);
  useEffect(() => {
    pdf(<PDFPreview paper={paper.question_format} />)
      .toBlob()
      .then((blob) => {
        // console.log(blob);
        const reader = new FileReader();
        reader.onload = function (event) {
          // console.log(event.target.result);
          setPdfDataURL(event.target.result);
        };
        reader.readAsDataURL(blob);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      title={paper.index}
      style={{ width: "100%", height: "80vh" }}
      src={pdfDataURL}
    />
  );
};

export default RenderInstance;
