import { FC } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Upload from "./components/Upload";
import Home from "./components/Home";
import Report from "./components/report/Reports";
import Login from "./components/Login";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ForgotPassword from "./components/auth/ForgotPassword";
import ChangePassword from "./components/auth/ChangePassword";

const App: FC = () => {
  //ts-ignore
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        {/* <Route path="/upload"> */}
        {/* <Header title="Upload" link="Reports" /> */}
        {/* <Upload /> */}
        {/* </Route> */}
        <Route path="/reports">
          {/* <Header title="Reports" link="Upload" /> */}
          <Report />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password/:token">
          <ChangePassword />
        </Route>
        <Route path="*">
          <h1>404 Not found</h1>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
