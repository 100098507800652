import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  CardContent,
  Container,
  createStyles,
  IconButton,
  Modal,
  Theme,
  Typography,
  withStyles,
  Card,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { pdf } from "@react-pdf/renderer";
import PDFPreview from "../preview/PDFPreview";
import { lighten } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
// import SearchBar from "material-ui-search-bar";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import GetOTP from "./GetOTP";
import romanize from "../utils/romanize";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// import SyncIcon from "@material-ui/icons/Sync";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import RenderInstance from "../upload/RenderInstance";
import { storage } from "../../firebase";
import {
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";
//import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "White",
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: lighten("#911717", 0.15),
      //color: theme.palette.common.white,
      fontSize: 16,
      zIndex: 0,
    },
    body: {
      fontSize: 14,
      zIndex: 100,
    },
  })
)(TableCell);

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  })
)(TableSortLabel);

const Reports = () => {
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  //const [rows,setRows]=useState(appointments)
  // const [searched,setSearched]=useState("")
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState({ otp: "", hash: "", email: "", action: "" });
  const [appointmentID, setAppointmentID] = useState("");
  const [change, setChange] = useState(true);
  const [startDate, setStartDate] = useState(new Map());

  const [previewPDF, setPreviewPDF] = useState<any>({
    index: null,
    data: [],
  });
  const uploadRef = useRef();

  // eslint-disable-next-line
  const [sem, setSem] = useState([]);

  const [deptList, setDeptList] = useState([]);

  // eslint-disable-next-line
  const [dept, setdept] = useState([]);

  const [ClassList, setClassList] = useState([]);

  // eslint-disable-next-line
  const [SemesterFilter, setSemesterFilter] = useState([]);

  const [SemesterList, setSemesterList] = useState([]);

  const handleOpenOTP = async (_id) => {
    setOpen(true);
    setAppointmentID(_id);
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: "examsoftware.qp@gmail.com" }),
    });
    const { email, hash } = await res.json();

    setOtp((prevOtp) => {
      return { ...prevOtp, hash, email };
    });
  };
  const handleCloseOTP = () => {
    setOpen(false);
  };

  const History = useHistory();
  //async
  const fetchAppointments = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/appointments`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (sessionStorage.getItem("role") === "teacher") {
          data = data.filter((appointment) => {
            return (
              appointment.teacher.email === sessionStorage.getItem("email")
            );
          });
        }
        setAppointments(data);
        const Deptset = new Set();
        data.map(({ department }) => Deptset.add(department));
        const tempDeptList: string[] = [];
        Deptset.forEach((value: string) => tempDeptList.push(value));
        setDeptList(tempDeptList);

        const ClassSet = new Set();
        data.map(({ subject }) => ClassSet.add(subject));
        const tempClassList: string[] = [];
        ClassSet.forEach((value: string) => tempClassList.push(value));
        setClassList(tempClassList);

        const SemesterSet = new Set();
        data.map(({ semester }) => SemesterSet.add(semester));
        const tempSemesterList: string[] = [];
        SemesterSet.forEach((value: string) => tempSemesterList.push(value));
        setSemesterList(tempSemesterList);
      });
  };
  console.log(deptList);
  console.log(ClassList);
  console.log(SemesterList);

  useEffect(() => {
    const token = sessionStorage.getItem("login");
    if (token === null) {
      History.push("/login?redirect=reports");
    }
    fetchAppointments();
    // eslint-disable-next-line
  }, []);

  //console.log(appointments)

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [search]);

  // useEffect(() => {
  //   handleFilter();
  //   // eslint-disable-next-line
  // }, [dept,sem]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/appointments`)
      .then((res) => res.json())
      .then((data) => {
        if (change) {
          setChange(false);
          for (let i = 0; i < data.length; i++) {
            setStartDate(
              new Map(startDate.set(data[i]._id, new Date(data[i].examdate)))
            );
          }
        }
      });
  });

  useEffect(() => {
    if (otp.otp !== "" && open) {
      if (otp.action === "download") {
        downloadPaper();
      } else if (otp.action === "delete") {
        deletePaper();
      }
    }
    // eslint-disable-next-line
  }, [otp, open]);

  const readFileChosen = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        // @ts-ignore
        resolve(JSON.parse(fileReader.result));
      };
      fileReader.onerror = reject;
      fileReader.readAsText(file);
    });
  };

  const uploadPDF = async (file) => {
    return new Promise((resolve, reject) => {
      const appointmentRef = storage.ref().child("QP").child(appointmentID);
      appointmentRef
        .child(file.name)
        .put(file)
        .then(async (_snapshot) => {
          const downloadURL = await _snapshot.ref.getDownloadURL();
          resolve(downloadURL);
        })
        .catch((err) => reject(err));
    });
  };

  const readAllFiles = async (allFiles) => {
    const results = await Promise.all(
      allFiles.map(async (file, index) => {
        if (file.type === "application/json") {
          const fileContents = await readFileChosen(file);
          return {
            paper_num: index + 1,
            paperType: "json",
            question_format: fileContents,
          };
        } else {
          const pdfURL = await uploadPDF(file);
          return { paper_num: index + 1, paperType: "pdf", pdfURL };
        }
      })
    );
    return results;
  };

  const handlePaperUpload = async (e) => {
    const NUM_PAPERS = 3;
    const files = e.target.files;

    if (Array.from(files).length !== NUM_PAPERS) {
      e.preventDefault();
      e.target.value = "";
      alert(`You need to upload ${NUM_PAPERS} papers!`);
      return;
    }

    let data;
    try {
      data = await readAllFiles([...files]);
    } catch (error) {
      console.log(error);
    }
    setPreviewPDF({ index: 0, data });
    e.target.value = "";
  };

  const qpJsonToPdf = (fileName: any, question_format: any) => {
    pdf(<PDFPreview paper={question_format} />)
      .toBlob()
      .then(async (blob) => {
        const href = await URL.createObjectURL(blob);
        downloadPDF(fileName, href);
      });
  };

  const downloadPDF = (fileName, href) => {
    fetch(href, {
      method: "GET",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const verifyOTP = async () => {
    const resVerified = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/otp/verify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otp),
      }
    );
    const verified = await resVerified.json();
    return verified;
  };

  const downloadPaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`)
        .then((res) => res.json())
        .then(
          ({ appointment: { subject, semester, department }, questions }) => {
            const fileName = `${subject}-${department} ${semester}.pdf`;
            if (questions.paperType === "json") {
              qpJsonToPdf(fileName, questions.question_format);
            } else {
              downloadPDF(fileName, questions.pdfURL);
            }
            setOpen(false);
          }
        );
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  const uploadPaper = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/paper`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ appointmentID, data: previewPDF.data }),
    })
      .then((res) => res.json())
      .then((data) => {
        // fetchAppointments();
        window.location.reload();
        setPreviewPDF({
          index: null,
          data: [],
        });
      })
      .catch((err) => console.log(err));
  };

  const deletePaper = async () => {
    const verified = await verifyOTP();
    if (verified.msg === "Verified") {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/paper?id=${appointmentID}`, {
        method: "DELETE",
      })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      alert(verified.err);
    }
    setOtp({ otp: "", hash: "", email: "", action: "" });
  };

  // const getAppointmentFromIT = () => {
  //   fetch("http://localhost:8000/user/login", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email: "mitanshu.r@somaiya.edu",
  //       password: "1EfzT6WD",
  //     }),
  //     credentials: "include",
  //   })
  //     .then((res) => res.json())
  //     .then((_data) => {
  //       fetch("http://localhost:8000/appointments", {
  //         credentials: "include",
  //       })
  //         .then((res) => res.json())
  //         .then((data) => console.log(data));
  //     });
  // };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator, order, orderBy) {
    let sortedArray = array;
    sortedArray.sort(comparator);
    return sortedArray;
  }

  //r -> rows ; a-> appointments
  function display(r, a) {
    if (r.length === 0) {
      return a;
    } else {
      return r;
    }
  }

  //@ts-ignore
  const handleRequestSort = (event, property) => {
    const isAsc: boolean = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const requestSearch = (searchedVal:string) => {
  //   const filtered=appointments.filter((app)=>{
  //     return app.subject.includes(searchedVal)
  //   })
  //   console.log(filtered)
  //   return filtered
  // }

  // const cancelSearch = () => {
  //   setSearched("")
  // }
  const handleSearch = () => {
    let filtered;
    filtered = appointments.filter(
      (app) =>
        app.subject.toLowerCase().includes(search.toLowerCase()) ||
        app.department.toLowerCase().includes(search.toLowerCase()) ||
        app.level.toLowerCase().includes(search.toLowerCase()) ||
        app.subjectCode.toLowerCase().includes(search.toLowerCase()) ||
        app.syllabusRevision.toLowerCase().includes(search.toLowerCase()) ||
        app.examdate.toLowerCase().includes(search.toLowerCase()) ||
        app.teacher.name.toLowerCase().includes(search.toLowerCase()) ||
        app.semester.toString().toLowerCase().includes(search.toLowerCase())
    );
    setRows(filtered);
  };

  // const handleFilter = () => {
  //   let filtered;
  //   filtered = rows.filter(
  //     (app) =>

  //       app.department.toLowerCase().includes(dept.toLowerCase()) &&
  //       app.semester.toString().toLowerCase().includes(sem.toLowerCase())
  //   );
  //   setRows(filtered);
  // };
  //const handleDeptFilterChange = (e) =>{
  //   setSearch(e.target.value)
  // }

  const handleDeptFilter = (e) => {
    let filtered;
    filtered = appointments.filter((app) =>
      app.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRows(filtered);
  };
  const handleSemFilter = (e) => {
    let filtered;
    filtered = appointments.filter((app) =>
      app.semester
        .toString()
        .toLowerCase()
        .includes(e.target.value.toString().toLowerCase())
    );
    setRows(filtered);
  };

  const handleSubFilter = (e) => {
    let filtered;
    filtered = appointments.filter((app) =>
      app.subject.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRows(filtered);
  };

  const handleChangeDate = (_id, date) => {
    alert(`Are you sure you want to change the date baka`);
    //@ts-ignore
    setStartDate(new Map(startDate.set(_id, date)));
    //post request
    fetch(`${process.env.REACT_APP_BACKEND_URL}/timetable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: _id, new_date: date }),
    })
      .then((res) => res.json())
      .then((data) => window.location.reload());
    console.log(change);
    console.log(startDate);
  };

  return (
    <Container maxWidth="lg">
      <GetOTP open={open} handleClose={handleCloseOTP} handleOTP={setOtp} />
      {sessionStorage.getItem("role") && (
        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          {sessionStorage.getItem("role") === "teacher"
            ? "Convenor"
            : sessionStorage.getItem("role") === "admin"
            ? "Exam section Admin"
            : "Super Admin"}{" "}
          View
          <br></br>
          {"Name : " + sessionStorage.getItem("name")}
          <br></br>
          {" Email : " + sessionStorage.getItem("email")}
        </Typography>
      )}
      {/* {sessionStorage.getItem("role") === "superAdmin" && (
        <Button
          style={{ backgroundColor: "#911717", color: "white" }}
          variant="contained"
          startIcon={<SyncIcon />}
          onClick={getAppointmentFromIT}
        >
          Get new appointments
        </Button>
      )}
      <br /> */}
      <TextField
        style={{ marginTop: "2vh", marginBottom: "2vh" }}
        id="filled-search"
        color="primary"
        type="search"
        label="Search"
        variant="filled"
        //fullWidth
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ margin: "5px", width: "200px" }}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="demo-simple-select-outlined-label">
              Department
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Exam Month"
              onChange={(e) => {
                handleDeptFilter(e);
              }}
            >
              {deptList.map((o) => (
                <MenuItem value={o}>{o}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: "5px", width: "200px" }}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="demo-simple-select-outlined-label">
              Semester
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Sem"
              onChange={(e) => {
                handleSemFilter(e);
              }}
            >
              {SemesterList.map((o) => (
                <MenuItem value={o}>{o}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: "5px", width: "200px" }}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="demo-simple-select-outlined-label">
              Subject Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="year"
              onChange={(e) => {
                handleSubFilter(e);
              }}
            >
              {ClassList.map((o) => (
                <MenuItem value={o}>{o}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <br />

      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                //{key:"serial_no",display:"SR No."}
                { key: "level", display: "Level" },
                { key: "department", display: "Department" },
                //{ key: "examTitle", display: "Exam Title" },
                { key: "semester", display: "Semester" },
                { key: "subjectCode", display: "Subject Code" },
                { key: "subject", display: "Subject Name" },
                { key: "syllabusRevision", display: "Syllabus Revision" },
                { key: "questionPaperCode", display: "Exam Date" },
                { key: "teacher", display: "Convener" },
                { key: "submitted", display: "Submitted" },
              ].map(({ key, display }, index) => {
                return (
                  <StyledTableCell align="right" key={index}>
                    <StyledTableSortLabel
                      active={orderBy === key}
                      //@ts-ignore
                      direction={orderBy === key ? order : "asc"}
                      onClick={(e) => {
                        handleRequestSort(e, key);
                      }}
                    >
                      {display}
                    </StyledTableSortLabel>
                  </StyledTableCell>
                );
              })}
              {sessionStorage.getItem("role") !== "admin" && (
                <StyledTableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              display(rows, appointments),
              getComparator(order, orderBy),
              order,
              orderBy
            ).map(
              (
                {
                  level,
                  year,
                  department,
                  semester,
                  subject,
                  subjectCode,
                  syllabusRevision,
                  examdate,
                  teacher,
                  submitted,
                  _id,
                },
                index
              ) => (
                <TableRow hover key={index}>
                  <StyledTableCell align="right">{level}</StyledTableCell>
                  <StyledTableCell align="right">{department}</StyledTableCell>
                  <StyledTableCell align="right">
                    {romanize(semester)}
                  </StyledTableCell>
                  <StyledTableCell align="right">{subjectCode}</StyledTableCell>
                  <StyledTableCell align="right">{subject}</StyledTableCell>

                  <StyledTableCell align="right">
                    {syllabusRevision}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{ position: "static", zIndex: 999 }}
                  >
                    {sessionStorage.getItem("role") !== "teacher" && (
                      <DatePicker
                        selected={startDate.get(_id)}
                        onChange={(date) => handleChangeDate(_id, date)}
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                    {sessionStorage.getItem("role") === "teacher" && (
                      <DatePicker
                        selected={startDate.get(_id)}
                        locale="pt-BR"
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {teacher.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {submitted ? <CheckCircleIcon /> : <CancelIcon />}
                  </StyledTableCell>
                  {sessionStorage.getItem("role") !== "admin" && (
                    <StyledTableCell align="right">
                      <div style={{ display: "flex" }}>
                        {sessionStorage.getItem("role") === "superAdmin" && (
                          <IconButton
                            disabled={!submitted}
                            onClick={() => {
                              handleOpenOTP(_id);
                              setOtp((prevState) => {
                                return { ...prevState, action: "download" };
                              });
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {previewPDF.data &&
        previewPDF.data.map((paper, index) => {
          return (
            <Modal
              key={index}
              open={previewPDF.index === index}
              onClose={() =>
                setPreviewPDF({ index: null, data: previewPDF.data })
              }
            >
              <Card key={index}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {paper.paperType === "pdf" ? (
                      <iframe
                        title={paper.index}
                        style={{ width: "100%", height: "80vh" }}
                        src={paper.pdfURL}
                      />
                    ) : (
                      <RenderInstance paper={paper} />
                    )}
                    <div style={{ display: "flex", alignSelf: "flex-start" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={previewPDF.index === 0}
                        onClick={() =>
                          setPreviewPDF(({ index, data }) => {
                            // @ts-ignore
                            const newIndex = index - 1;
                            return { data, index: newIndex };
                          })
                        }
                      >
                        Previous
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={previewPDF.index === 2}
                        style={{ marginLeft: "1rem" }}
                        onClick={() =>
                          setPreviewPDF(({ index, data }) => {
                            // @ts-ignore
                            const newIndex = index + 1;
                            return { data, index: newIndex };
                          })
                        }
                      >
                        Next
                      </Button>
                      {previewPDF.index === 2 && (
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginLeft: "1rem" }}
                          onClick={() => {
                            window.confirm(
                              "Are you sure you want to submit?"
                              // ) && console.log("submitting");
                            ) && uploadPaper();
                          }}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Modal>
          );
        })}
    </Container>
  );
};

export default Reports;
